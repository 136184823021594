import Modal from '../utils/Modal';
import useTranslation from '../../hooks/useTranslation';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Submit, Text } from '../utils/form';
import Icon from '../utils/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { preregister, showNewSignupPopupAction } from 'actions/user';
import message from '../utils/message';
import { setPopupNewsLetterShown } from 'actions/ui';
import { useRouter } from 'next/router';

function PopupNewsletter() {
  const { TRANSLATIONS, translationsParameters, locale } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const popupNewsLetterShown = useSelector(
    (state) => state.UI.popupNewsLetterShown
  );
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!popupNewsLetterShown) {
        setModalOpen(true);
        dispatch(setPopupNewsLetterShown({ popupNewsLetterShown: true }));
      }
    }, 20000);
    return () => {
      clearTimeout(handler);
    };
  }, [dispatch, popupNewsLetterShown]);

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const onSubmit = useCallback(
    (data) => {
      setIsLoading(true);
      dispatch(
        preregister(
          {
            email: data?.email,
            name: data?.name,
            lang: locale?.toUpperCase(),
            useLoader: false,
            DidYouKnowBDroppy: 'BDroppy_popup',
          },
          (response) => {
            if (
              response?.data?.error === 'Contact Email Address is not valid.'
            ) {
              message.error(TRANSLATIONS.popupNewsletter.invalidEmail);
            } else {
              dispatch(showNewSignupPopupAction(true));
              setModalOpen(false);
            }
            setIsLoading(false);
          }
        )
      );
    },
    [TRANSLATIONS.popupNewsletter.invalidEmail, dispatch, locale]
  );

  if (
    router.pathname.includes('pricing') ||
    router.pathname.includes('payment') ||
    router.pathname.includes('channel')
  )
    return null;

  return (
    <>
      <Modal
        openModal={modalOpen}
        showHeader={false}
        modalContentWrapperClassname="popup-newsletter-wrapper"
        modalContentClassname="popup-newsletter-content"
        onClose={onCloseModal}
      >
        <div className="popup-newsletter-container">
          <div className="container">
            <div className="title-container">
              <p className="title">{TRANSLATIONS.popupNewsletter.wait}</p>
              <p className="title mb-md text-bold">
                {TRANSLATIONS.popupNewsletter.title}
              </p>
              <p className="subtitle mb-md">
                {translationsParameters(TRANSLATIONS.popupNewsletter.subtitle, [
                  <span className="text-bold" key="text-bold">
                    {TRANSLATIONS.popupNewsletter.subtitleBold}
                  </span>,
                ])}
              </p>
            </div>
            <div className="form-container">
              <Form onSubmit={onSubmit}>
                <div className="d-f fd-c g-20 mb-xxl form">
                  <Text
                    name="name"
                    label={TRANSLATIONS.popupNewsletter.name}
                    required
                  />
                  <Text noCapitalize name="email" label="Email" required />
                </div>
                <Submit
                  label={TRANSLATIONS.popupNewsletter.cta}
                  className="large button primary wide"
                  loading={isLoading}
                />
              </Form>
            </div>
            <div>
              <p className="bottom-text ta-c">
                {translationsParameters(
                  TRANSLATIONS.popupNewsletter.disclaimer,
                  [
                    <a
                      className="link"
                      href={`${process.env.DASHBOARD_URL}/privacy-policy`}
                      target={'_blank'}
                      rel="noreferrer"
                      key="privacy"
                    >
                      {TRANSLATIONS.popupNewsletter.privacy}
                    </a>,
                    <a
                      className="link"
                      href={'mailto:contact@bdroppy.com'}
                      target={'_blank'}
                      rel={'noreferrer'}
                      key="mail"
                    >
                      {TRANSLATIONS.popupNewsletter.contattaci}
                    </a>,
                  ]
                )}
              </p>
              <p className="bottom-text ta-c">
                {TRANSLATIONS.popupNewsletter.unsubscribe}
              </p>
            </div>
          </div>
          <div className="image-container">
            <img src="/popup-newsletter.png" alt="Bdroppy" />
          </div>
          <div className="close-icon" onClick={onCloseModal}>
            <Icon type="x" />
          </div>
        </div>
      </Modal>
      <style jsx>{`
        .text-bold {
          font-weight: bold;
        }
        .container {
          padding: 51px 35px;
          border-radius: 24px;
          align-items: center;
          display: flex;
          flex-direction: column;
          gap: 23px;
          justify-content: center;
          position: relative;
          flex: 2;
        }
        .title-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .title {
          font-size: 24px;
          line-height: 30px;
          width: 80%;
        }
        .subtitle {
          font-size: 16px;
          line-height: 20px;
        }
        .bottom-text {
          font-size: 14px;
          line-height: 20px;
        }
        .form {
          font-weight: bold;
        }
        .close-icon {
          position: absolute;
          top: 16px;
          right: 16px;
          height: 42px;
          width: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #d4d4d4;
          border-radius: 34px;
          box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.13);
          cursor: pointer;
          background: white;
        }
        .popup-newsletter-container {
          display: flex;
          position: relative;
          max-width: 1002px;
        }
        .image-container {
          flex: 2;
          display: none;
        }
        .form-container {
          width: 100%;
          max-width: 350px;
        }
        .link {
          color: var(--primary);
          font-weight: 600;
          cursor: pointer;
        }
        @media screen and (min-width: 1002px) {
          .image-container {
            display: block;
            min-width: 430px;
          }
          .title {
            width: 100%;
          }
          .subtitle {
            width: 70%;
          }
          .form-container {
            padding: 0 35px;
          }
        }
      `}</style>
    </>
  );
}

export default PopupNewsletter;
