import css from 'styled-jsx/css';

export default css`
  .integrations {
    background: white;
    border-radius: 5px;
    box-shadow: var(--shadow-light);
    width: 280px;
  }
  .integrations img {
    padding: 1em;
    max-width: 170px;
  }
  :global(html) {
    scroll-behavior: smooth !important;
  }
  :global(.content) {
    max-width: none !important;
  }
  :global(main) {
    padding: 0px !important;
  }
  .reseller {
    box-sizing: border-box;
    margin: auto;
    overflow: hidden;
  }
  h1,
  h2,
  h3,
  p,
  li {
    color: var(--grey-dark);
  }
  .reseller-header-wrapper,
  .reseller-block-wrapper {
    margin: auto;
    max-width: 1300px;
  }
  .reseller-header {
    padding: 35px 25px;
  }
  .reseller-block {
    padding: 60px 25px;
  }
  .reseller-block p,
  .reseller-block :global(h3) {
    color: white;
  }
  .blue-gradient {
    background-color: transparent;
    background-image: linear-gradient(110deg, #e6f2ff 5%, #edfcfb 100%);
  }
  .dark-blue {
    background-color: transparent;
    background-image: linear-gradient(140deg, #161b31 0%, #03060e 100%);
  }
  .purple {
    background-color: transparent;
    background-image: linear-gradient(100deg, #3c46a2 25%, #4f73c3 100%);
  }
  .white {
    color: white !important;
  }
  .black {
    color: var(--grey-dark) !important;
  }
  .reseller-block :global(p) {
    margin: auto;
    line-height: 22px;
  }
  .box_black {
    padding: 1em;
    background: #1e223c;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    margin: 10px 10px 10px 10px;
  }
  .box {
    padding: 1em;
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    margin: 10px 10px 10px 10px;
  }
  .about-numbers,
  .about-box-wrapper {
    padding: 75px 25px;
  }
  .text-gradient {
    background: linear-gradient(130deg, #005eff 30.5%, #00e4b8 94.94%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
  }
  .box-blue {
    padding: 1em 1em 6em;
  }
  .box-blue ul li {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  .box-blue-img {
    position: relative;
    top: -100px;
    margin-bottom: -100px;
  }
  .integrations {
    background: white;
    border-radius: 8px;
    height: 100px;
  }
  .integrations img {
    max-width: 180px;
  }
  .col-integrations {
    max-width: 20%;
    margin: 8px 0px;
  }
  .play-btn {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 80px;
    height: 80px;
    background: #2c65ae;
    box-shadow: 0px 0px 0px 20px rgba(158, 185, 210, 0.5);
    padding: 1em;
    border-radius: 100%;
  }
  @media all and (max-width: 768px) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 22px;
    }
    .number span {
      font-size: 28px;
      text-align: center;
    }
    .col-integrations {
      max-width: 100%;
      margin: 8px 0px;
    }
    .integrations img {
      max-width: 120px;
    }
    .box-blue {
      padding: 1em 1em 1em;
    }
    .box-blue-img {
      position: relative;
      top: 0px;
      margin-bottom: 0px;
    }
    .reseller-header {
      padding: 25px 25px;
    }
    .reseller-block {
      padding: 30px 25px;
    }
  }
`;
